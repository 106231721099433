import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import cn from 'classnames'
import { useTranslations } from '../../hooks/use-translations'
import { IFeedback } from './types'

interface Props extends Omit<IFeedback, 'photoId'> {
  className?: string
  image: IGatsbyImageData
}

const Card: React.FC<Props> = ({
  name,
  image,
  className,
  position,
  text,
  children,
}) => {
  const { t } = useTranslations()
  return (
    <div className={cn('feedback_card', className)}>
      <div className="feedback_photo">
        {image && (
          <GatsbyImage
            image={image}
            imgStyle={{ borderRadius: '50%', backgroundColor: 'c4c4c4' }}
            alt={t(name)}
            title={t(name)}
          />
        )}
      </div>
      <div className="feedback_author">{t(name)}</div>
      <div className="feedback_position">{t(position)}</div>
      <p className="feedback_title">{t(text)}</p>
      {children}
    </div>
  )
}

export default Card
